import React from 'react';
import styles from './HistoryTab.module.scss';

const HistoryTab = () => (
  <div className={styles.HistoryTab}>
    HistoryTab Component
  </div>
);

export default HistoryTab;

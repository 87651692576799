import React from 'react';
import styles from './PermissionsTab.module.scss';

const PermissionsTab = () => (
  <div className={styles.PermissionsTab}>
    PermissionsTab Component
  </div>
);

export default PermissionsTab;
